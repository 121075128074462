import { Box, SxProps, Theme, useTheme } from '@mui/material'
import React, { FunctionComponent, ReactNode, SVGProps } from 'react'

import { ReactComponent as DatabaseIcon } from '../../../../../assets/images/Database_Icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/Delete_Icon.svg'
import { ReactComponent as EyeIcon } from '../../../../../assets/images/Eye_Icon.svg'
import { ReactComponent as LanguagesIcon } from '../../../../../assets/images/Languages_Icon.svg'
import { ReactComponent as MultiSelectCursor } from '../../../../../assets/images/multi_select_cursor.svg'
import { ReactComponent as QuestionsIcon } from '../../../../../assets/images/Questions_Icon.svg'
import { ReactComponent as ResetIcon } from '../../../../../assets/images/Reset_Icon.svg'
import { ReactComponent as SearchIcon } from '../../../../../assets/images/Search.svg'
import { ReactComponent as SwitchIcon } from '../../../../../assets/images/Switch_Icon.svg'

export enum Icons {
  Delete,
  Reset,
  Questions,
  Database,
  Switch,
  Languages,
  Eye,
  Search,
  MultiSelectCursor,
}

const icons: {
  [key in Icons]: FunctionComponent<SVGProps<SVGSVGElement>>
} = {
  [Icons.Delete]: DeleteIcon,
  [Icons.Reset]: ResetIcon,
  [Icons.Questions]: QuestionsIcon,
  [Icons.Database]: DatabaseIcon,
  [Icons.Switch]: SwitchIcon,
  [Icons.Languages]: LanguagesIcon,
  [Icons.Eye]: EyeIcon,
  [Icons.Search]: SearchIcon,
  [Icons.MultiSelectCursor]: MultiSelectCursor,
}

interface CustomIconProps {
  icon: Icons
  children?: ReactNode
  sx?: SxProps<Theme>
  width?: number
  height?: number
  color?: string
}

const CustomIcon = ({
  icon,
  sx = [],
  width = 16,
  height = 16,
  color,
  children,
}: CustomIconProps) => {
  const theme = useTheme()
  const Icon = icons[icon]

  return (
    <Box sx={sx} display="flex" alignItems="center">
      {color ? (
        <Icon width={width} height={height} color={color} />
      ) : theme.palette.mode === 'dark' ? (
        <Icon width={width} height={height} color="white" />
      ) : (
        <Icon width={width} height={height} color="black" />
      )}
      {children}
    </Box>
  )
}

export default CustomIcon
