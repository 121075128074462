import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import * as queryStates from '../../queryStates'
import { Language } from '../../types'

export const LANG_QUERY = gql`
  query Languages {
    languages {
      id
      name
      example
      databaseAllowed
      isDatabaseLanguage
      testCaseGrading
      customFileAllowed
      category
    }
  }
`

export interface UseLanguagesData {
  languages: Language[]
}

export function useLanguages() {
  const { data, error, loading } = useQuery<UseLanguagesData>(LANG_QUERY)

  const languagesById = useMemo<Record<string, Language>>(
    () =>
      (data?.languages || []).reduce((acc, lang) => {
        acc[lang.id] = lang
        return acc
      }, {}),
    [data?.languages]
  )

  const status = useMemo(() => {
    if (loading) {
      return queryStates.loading()
    } else if (error) {
      return queryStates.error('Failed to load languages', error)
    } else {
      return queryStates.success()
    }
  }, [error, loading])

  return useMemo(
    () => ({
      languages: data?.languages || [],
      languagesById,
      status,
    }),
    [data?.languages, languagesById, status]
  )
}
