import { Collapse, Snackbar } from '@mui/material'
import { Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { getErrorNotice, getNotice, Notice } from '../../../../utils/notices/notices'

interface ILocationStateMsg {
  message: {
    severity?: string
    msgKey: Notice
  }
}

export const AppNotice: React.FC = () => {
  const { state } = useLocation()
  const appMessage = window.CP_APP_NOTICE
  const [errorMessage, setErrorMessage] = useState(window.CP_APP_ERRORS)
  const [frontendNotice, setFrontendNotice] = useState<string>()
  const [frontendError, setFrontendError] = useState<string>()
  const { hash } = useLocation()
  const [isOpen, setIsOpen] = useState(!!errorMessage || !!appMessage || !!frontendNotice)

  useEffect(() => {
    const noticeKey = (hash == null ? '' : hash.substring(1)) as Notice
    const notice = getNotice(noticeKey)
    if (notice) {
      if (getErrorNotice(noticeKey)) {
        setFrontendError(notice)
      } else {
        setFrontendNotice(notice)
      }
      setIsOpen(true)
    }
  }, [hash])

  useEffect(() => {
    if (state) {
      const stateMsg = (state as ILocationStateMsg).message
      if (stateMsg?.msgKey) {
        const msg = getNotice(stateMsg.msgKey)
        if (msg) {
          if (stateMsg.severity === 'error') {
            setErrorMessage(msg)
          } else {
            setFrontendNotice(msg)
          }
          setIsOpen(true)
        }
      }
    }
  }, [state])

  return (
    <Collapse in={isOpen} sx={{ width: '100%', maxWidth: '968px' }}>
      <Snackbar
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={errorMessage || frontendError ? null : 10000}
        data-testid="app-notice"
      >
        <Alert
          severity={errorMessage || frontendError ? 'error' : 'success'}
          onClose={() => setIsOpen(false)}
          sx={{ border: '1px solid currentColor', marginBottom: 2 }}
        >
          {errorMessage || appMessage || frontendNotice || frontendError}
        </Alert>
      </Snackbar>
    </Collapse>
  )
}
