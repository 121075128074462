import { createContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnalyticsContextTrack<TParams = any> = (
  // TODO: Replace this with typed AnalyticsEvent when the type is created
  eventName: string,
  params?: TParams
) => Promise<void>

export interface AnalyticsContextValue {
  track?: AnalyticsContextTrack
}

export const AnalyticsContext = createContext<AnalyticsContextValue>({})
