import { styled } from '@mui/material'
import { Box } from '@mui/system'

export const ScrollView = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  overflow: 'auto',
  // color-scheme allows us to use the system scrollbars in dark mode.
  // this is here so that if custom scrollbar styles are blocked/disabled,
  // they won't see a white scrollbar.
  colorScheme: 'dark',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  '&::-webkit-scrollbar': {
    width: 12,
    height: 12,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.scrollbar.color,
    borderRadius: 9999,
    backgroundClip: 'padding-box',
    border: '4px solid transparent',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.scrollbar.hover,
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: theme.palette.scrollbar.active,
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
}))
