import { useContext, useMemo } from 'react'

import { track as trackImplementation } from '../../packs/main/coderpad_analytics'
import { AnalyticsContext } from './AnalyticsContext'

export function useAnalytics() {
  const { track } = useContext(AnalyticsContext)

  return useMemo(
    () => ({
      track: track === undefined ? trackImplementation : track,
    }),
    [track]
  )
}
