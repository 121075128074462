import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import { useMemo } from 'react'

import { useWindow } from '../../../../../utils'

export function useHasSpreadsheets() {
  const window = useWindow()
  const { slug: padSlug, hasSpreadsheets: padConfigHasSpreadsheets } = usePadConfigValues(
    'slug',
    'hasSpreadsheets'
  )

  const hasSpreadsheets = useMemo(() => {
    return padSlug == null || padSlug === ''
      ? window.CoderPad.FLAGS.spreadsheetPads
      : padConfigHasSpreadsheets
  }, [padConfigHasSpreadsheets, padSlug, window.CoderPad.FLAGS.spreadsheetPads])

  return hasSpreadsheets
}
