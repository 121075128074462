import {
  Alert,
  Box,
  Chip,
  List,
  ListItemButton,
  Stack,
  styled,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { Language, ProjectTemplate } from 'graphql/types'
import { PadAnalyticsEvent } from 'packs/main/constants/PadAnalyticsEvent'
import trackEvent from 'packs/main/track_event'
import React, { useCallback, useMemo, useState } from 'react'

import { useProjectTemplates } from '../../../../../graphql/hooks/useProjectTemplates/useProjectTemplates'
import * as queryStates from '../../../../../graphql/queryStates'
import { LanguageConfiguration, useWindow } from '../../../../../utils'
import { LoadingBlock } from '../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { EnvironmentTypes } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import { ScrollView as _ScrollView } from '../../../ScrollView/ScrollView'
import { SearchField } from '../SearchField/SearchField'
import { LangItem } from './LangItem'
import { useHasSpreadsheets } from './useHasSpreadsheets'
import {
  isProjectTemplateVariation,
  SupportedLanguage,
  useSupportedLanguages,
} from './useSupportedLanguages'

const ScrollView = styled(_ScrollView)({
  flex: 1,
  minWidth: 0,
})

const LangItems = styled(Box)(({ theme }) => ({
  columnGap: 0,
  columnCount: 4,
  [theme.breakpoints.down('md')]: {
    columnCount: 2,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    columnCount: 3,
  },
}))

const CategoryItem = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.languageSelector.categoryFilter.active,
    '&:hover': {
      backgroundColor: theme.palette.languageSelector.categoryFilter.active,
    },
  },
  borderRadius: theme.shape.borderRadius,
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1.5),
  },
}))
const CategoryList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.languageSelector.categoryFilter.bg,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}))

interface Category {
  /** Label used in the category filter. */
  label: string
  /** Unique among categories in the category filter. */
  key: string
  /**
   * Used to filer the supported languages shown for this category. Each supported language is passed to this fn.
   * Returning true will include the language in the list. Returning false null will exclude it.
   */
  languageFilter: (l: SupportedLanguage) => boolean
  /** Text displayed in a tooltip on the category item. */
  hint?: string
  /**
   * Add additional tag/chip added next to the category label.
   */
  callout?: string
  availableWithoutExecution: boolean
}
const CATEGORIES: Category[] = [
  {
    label: 'All languages',
    key: 'all',
    languageFilter: (l: SupportedLanguage) => true,
    availableWithoutExecution: true,
  },
  {
    label: 'Multi-file Projects',
    key: 'multifile',
    languageFilter: (l: SupportedLanguage) => {
      return isProjectTemplateVariation(l) && l.category === 'multifile'
    },
    hint:
      'Multi-file projects include a shell and package installation now available for popular languages.',
    availableWithoutExecution: false,
  },
  {
    label: 'Frontend Frameworks',
    key: 'frontend',
    languageFilter: (l: SupportedLanguage) => {
      return isProjectTemplateVariation(l) && l.category === 'frontend'
    },
    availableWithoutExecution: false,
  },
  {
    label: 'Backend Frameworks',
    key: 'backend',
    languageFilter: (l: SupportedLanguage) => {
      return isProjectTemplateVariation(l) && l.category === 'backend'
    },
    availableWithoutExecution: false,
  },
  {
    label: 'Mobile',
    key: 'mobile',
    languageFilter: (l: SupportedLanguage) => {
      return isProjectTemplateVariation(l) && l.category === 'mobile'
    },
    callout: 'New',
    availableWithoutExecution: false,
  },
  {
    label: 'SQL',
    key: 'data',
    languageFilter: (l: SupportedLanguage) => {
      return !isProjectTemplateVariation(l) && l.category === 'data'
    },
    availableWithoutExecution: true,
  },
  {
    label: 'Spreadsheets',
    key: 'spreadsheets',
    languageFilter: (l: SupportedLanguage) => {
      return l.category === 'spreadsheets'
    },
    availableWithoutExecution: false,
  },
  {
    label: 'Jupyter Notebooks',
    key: 'notebooks',
    languageFilter: (l: SupportedLanguage) => {
      return isProjectTemplateVariation(l) && l.category === 'jupyter'
    },
    availableWithoutExecution: false,
  },
  {
    label: 'Documentation',
    key: 'documentation',
    languageFilter: (l: SupportedLanguage) => {
      return !isProjectTemplateVariation(l) && l.category === 'documentation'
    },
    availableWithoutExecution: true,
  },
]
export const CATEGORIES_SUPPORTING_DISABLED_EXECUTION = [
  'compiled',
  'interpreted',
  'data',
  'documentation',
]

interface LanguageEnvironmentsProps {
  onSelect: (lang: LanguageConfiguration) => void
  execEnabled: boolean
  onEnableExecutionClick?: () => void
  languageLimitationReason: string
  testCaseLangsOnly?: boolean
  includeTextSearch?: boolean
  sx?: SxProps
}
export function LanguageEnvironments(props: LanguageEnvironmentsProps) {
  const {
    onSelect,
    execEnabled,
    onEnableExecutionClick,
    testCaseLangsOnly = false,
    includeTextSearch = true,
    languageLimitationReason,
    sx,
  } = props
  const padSlug = usePadConfigValue('slug')
  const { supportedLanguages, status } = useSupportedLanguages(padSlug)
  const [activeCategory, setActiveCategory] = useState<string | null>('all')
  const window = useWindow()
  const hasSpreadsheets = useHasSpreadsheets()

  const { projectTemplates } = useProjectTemplates()
  const getLanguageConfiguration = useCallback(
    (name: string) => {
      const projectTemplate = projectTemplates.find(({ slug }) => slug === name)

      return projectTemplate
        ? ({
            name,
            display: projectTemplate.name,
            type: 'project',
            id: projectTemplate.id,
            category: projectTemplate.category,
          } as LanguageConfiguration)
        : window.CoderPad.LANGUAGES[name]
    },
    [projectTemplates, window.CoderPad.LANGUAGES]
  )

  const category = useMemo(() => {
    return CATEGORIES.find((c) => c.key === activeCategory)
  }, [activeCategory])
  const categoryOptions = useMemo(() => {
    const allCategories = hasSpreadsheets
      ? CATEGORIES
      : CATEGORIES.filter((c) => c.key !== 'spreadsheets')

    return execEnabled ? allCategories : allCategories.filter((c) => c.availableWithoutExecution)
  }, [execEnabled, hasSpreadsheets])
  const langOptions = useMemo(() => {
    const supportedLanguagesForExecutionSetting = supportedLanguages.filter((l) =>
      execEnabled ? true : CATEGORIES_SUPPORTING_DISABLED_EXECUTION.includes(l.category)
    )
    let langs
    // This is a backward compat thing for the question wizard, where only languages that we support test grading for are displayed.
    if (testCaseLangsOnly) {
      langs = supportedLanguages.filter((l) => !isProjectTemplateVariation(l) && l.testCaseGrading)
    } else {
      langs =
        category == null
          ? supportedLanguagesForExecutionSetting
          : supportedLanguagesForExecutionSetting.filter(category.languageFilter)
    }

    if (!hasSpreadsheets) {
      langs = langs.filter((l) => l.category !== 'spreadsheets')
    }

    return langs
  }, [category, execEnabled, hasSpreadsheets, supportedLanguages, testCaseLangsOnly])

  const onSearchSelect = useCallback(
    (type: EnvironmentTypes, language: Language | ProjectTemplate) => {
      const name =
        type === EnvironmentTypes.Project ? (language as ProjectTemplate).slug : language.name
      trackEvent(PadAnalyticsEvent.LanguageSearchResultSelected, {
        language: name,
        from: 'search',
      })
      const slug =
        type === EnvironmentTypes.Project
          ? (language as ProjectTemplate).slug
          : (language as Language).id
      const lang = getLanguageConfiguration(slug)
      onSelect(lang)
    },
    [getLanguageConfiguration, onSelect]
  )

  return (
    <Stack height="100%" boxSizing="border-box" sx={sx}>
      <Stack p={4.5} pb={0} height="100%" overflow="auto">
        {includeTextSearch ? (
          <SearchField onSelect={onSearchSelect} execEnabled={execEnabled} />
        ) : null}
        <Box display="flex" flex={1} minHeight={0}>
          {!testCaseLangsOnly ? (
            <Box flex="none" mr={4}>
              {/* Note the inclusion of a class name on this component. This allows ancestors to target and style it */}
              <CategoryList className="LanguageEnvironments-CategoryList">
                {categoryOptions.map((category) => {
                  const item = (
                    <CategoryItem
                      key={category.key}
                      onClick={() => setActiveCategory(category.key)}
                      selected={activeCategory === category.key}
                      className="LanguageEnvironments-CategoryItem"
                    >
                      <span>{category.label}</span>
                      {category.callout != null ? (
                        <Chip
                          label={category.callout}
                          sx={{ ml: 1.25 }}
                          size="small"
                          color="success"
                        />
                      ) : null}
                    </CategoryItem>
                  )
                  return category.hint != null ? (
                    <Tooltip title={category.hint} arrow placement="right" key={category.key}>
                      {item}
                    </Tooltip>
                  ) : (
                    item
                  )
                })}
              </CategoryList>
            </Box>
          ) : null}
          <ScrollView sx={{ pt: 1.5 }}>
            <LoadingBlock
              isLoading={queryStates.isLoadingState(status)}
              message="Loading Environments"
              minDisplayMS={0}
            >
              <LangItems>
                {langOptions.map((lang) => (
                  <LangItem
                    key={isProjectTemplateVariation(lang) ? lang.slug : lang.id}
                    lang={lang}
                    onClick={onSelect}
                  />
                ))}
              </LangItems>
            </LoadingBlock>
          </ScrollView>
        </Box>
      </Stack>
      {!execEnabled && (
        <Box m={1}>
          <Alert severity="error">
            <Typography>
              <b>Limited set of languages</b>
            </Typography>
            <Typography>
              Some languages and frameworks are not available because {languageLimitationReason}.
            </Typography>
            <Typography>
              To see all available languages,{' '}
              {onEnableExecutionClick != null && (
                <>
                  <a href="#" onClick={onEnableExecutionClick}>
                    enable execution
                  </a>{' '}
                  or{' '}
                </>
              )}
              <a href="/sandbox" target="_blank">
                visit the sandbox
              </a>
              .
            </Typography>
          </Alert>
        </Box>
      )}
    </Stack>
  )
}
