import 'firebase/compat/database'

import { monaco } from '@codingame/monaco-editor-wrapper'
import Firepad, { FirepadOptions, Headless } from '@codinpad/firepad'
import firebase from 'firebase/compat/app'

import padConfig from './pad_config'

export default class _FirebaseSyncHandle {
  public TIMESTAMP_SENTINEL: unknown
  private userId: string
  private firebaseRoot: firebase.database.Database
  private firepadRef: firebase.database.Reference
  constructor(userId: string) {
    firebase.initializeApp({ databaseURL: padConfig.databaseUrl })

    const firebaseRoot = firebase.database()
    if (!/firebaseio\.com$/.test(padConfig.databaseUrl)) {
      firebaseRoot.goOffline()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const repoInfo = (firebaseRoot as any)._delegate._repoInternal.repoInfo_
      repoInfo.internalHost = repoInfo.host
      repoInfo.constructor.prototype.isCacheableHost = () => false
      repoInfo.constructor.prototype.updateHost = () => undefined
      firebaseRoot.goOnline()
    }

    this.firebaseRoot = firebaseRoot
    this.firepadRef = firebaseRoot.ref(padConfig.slug)
    this.userId = userId
    // This is a sentinel value that tells Firebase to insert a server timestamp.
    this.TIMESTAMP_SENTINEL = firebase.database.ServerValue.TIMESTAMP

    if (padConfig.isSandbox && !padConfig.isScratchPad) firebaseRoot.goOffline()
  }

  // on is mostly for compatibility with OTD's :connected event,
  // most code to observe values should use watch and get.
  on<T>(path: string, fn: (obj: T) => void) {
    if (path == 'user_added' || path == 'user_changed' || path == 'user_removed') {
      const event = path.replace('user_', 'child_')
      this.firepadRef.child('users').on(event as firebase.database.EventType, function (userSnap) {
        const userObj = userSnap.val()
        userObj.userId = userSnap.key
        fn(userObj)
      })
    } else {
      this._ref(path).on('value', (val) => fn(val.val()))
    }
  }

  // watch a property
  watch<T>(path: string, fn: (obj: T) => void, defaultVal?: T) {
    const valueifiedCB = (snap: firebase.database.DataSnapshot) => {
      const val = snap.val()
      if (defaultVal != undefined && val == null) {
        // just return, this callback will fire again
        setTimeout(() => this._ref(path).set(defaultVal), 1)
        return
      }
      fn(val)
    }

    this._ref(path).on('value', valueifiedCB)

    // Return the callback for this path so that consumers can use that with `.off` to un-watch this path's value.
    return valueifiedCB
  }

  watchChildrenUpdates<T>(path: string, fn: (obj: T) => void) {
    const valueifiedCB = (snap: firebase.database.DataSnapshot) => {
      const val = snap.val()
      fn(val)
    }

    this._ref(path).on('child_changed', valueifiedCB)

    return valueifiedCB
  }

  watchChildrenAdditions<T>(path: string, fn: (obj: T) => void) {
    const valueifiedCB = (snap: firebase.database.DataSnapshot) => {
      const val = snap.val()
      fn(val)
    }

    this._ref(path).on('child_added', valueifiedCB)

    return valueifiedCB
  }

  off(path: string, fn: (obj: firebase.database.DataSnapshot) => void) {
    this._ref(path).off('value', fn)
    this._ref(path).off('child_changed', fn)
  }

  // get a property once
  get<T>(path: string, fn: (obj: T) => void, defaultVal?: T) {
    this._ref(path).once('value', (snap) => {
      let val = snap.val()
      if (defaultVal != undefined && val == null) {
        setTimeout(() => this._ref(path).set(defaultVal), 1)
        val = defaultVal
      }
      fn(val)
    })
  }

  // set an attribute
  set<T>(path: string, value: T, onComplete?: (a: Error | null) => void) {
    this._ref(path).set(value, onComplete)
  }

  // set the pad text
  setPadText(text: string, author = 'CoderPad') {
    this.firepadRef.set({ history: { A0: { a: author, o: [text], t: Date.now() } } })
  }

  firepadHeadless(document: string, options: { userId?: string } = {}) {
    const ref = this._ref(document == 'code' ? '' : document)
    return new Headless(ref, options)
  }

  firepad(model: monaco.editor.ITextModel, document: string, attrs: FirepadOptions = {}) {
    //we've got some magic going on when dealing with the code doc.
    if (document == 'code') {
      document = ''
    }

    const ref = this._ref(document)
    return new Firepad(monaco, model, ref, attrs)
  }

  // update an attribute
  update(path: string, value: unknown, onComplete: (a: Error | null) => void) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    this._ref(path).update(value as Object, onComplete)
  }

  update_user(value: unknown, onComplete: (a: Error | null) => void) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    this._user_ref().update(value as Object, onComplete)
  }

  // push data
  push(path: string, value: unknown) {
    this._ref(path).push(value)
  }

  // get a firebase ref
  _ref(path: string) {
    if (path === '') {
      return this.firepadRef
    } else if (path === ':connected') {
      return this.firebaseRoot.ref('.info/connected')
    }
    return this.firepadRef.child(path)
  }

  _user_ref() {
    return this._ref('users').child(this.userId)
  }
}
