import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  styled,
} from '@mui/material'
import React, { createContext, FC, useContext, useState } from 'react'

import { LanguageConfiguration } from '../../../utils/languages'
import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useEnvironments } from '../Environments/EnvironmentsContext/EnvironmentsContext'
import { LanguageInfo } from '../Languages/LanguageInfo'
import LanguageIcon from '../Monaco/FilePane/LanguageIcon'
import { ScrollView } from '../ScrollView/ScrollView'

export interface ILanguageInfoContext {
  languageModalOpen: boolean
  setLanguage: (lang: LanguageConfiguration) => void
}

const defaultLanguageInfoContext: ILanguageInfoContext = {
  languageModalOpen: false,
  setLanguage: () => {},
}

const LanguageInfoContext = createContext<ILanguageInfoContext>(defaultLanguageInfoContext)

const CloseButton = styled(IconButton)(({ theme }) => ({
  ['&.MuiIconButton-root']: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const LanguageContent = styled(ScrollView)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  fontSize: theme.typography.body1.fontSize,
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    color: theme.palette.text.primary,
  },
  '& .language-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .language-header h2': {
    color: theme.palette.text.primary,
    marginBottom: 0,
    marginTop: 0,
  },
  '& .language-header a': {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  '& a': {
    color: theme.palette.primary.main,
  },
  '& small': {
    display: 'block',
    padding: '5px 0',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  '& code': {
    background: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
  },
  '& ul': {
    paddingLeft: theme.spacing(2),
  },
}))

/**
 * Provider that handles fetching all the language information, parsing it,
 * and displaying it in a modal. The model is triggered via the `setLanguage`
 * function provided by the context.
 */
export const LanguageInfoProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState<LanguageConfiguration | null>(null)
  const { createOrSelectEnvironment } = useEnvironments()
  const { environment, question } = useActiveEnvironment()

  return (
    <LanguageInfoContext.Provider
      value={{
        ...defaultLanguageInfoContext,
        languageModalOpen: language != null,
        setLanguage,
      }}
    >
      {children}
      <Dialog
        maxWidth="md"
        open={language != null}
        onClose={(e: React.MouseEvent, reason) => {
          // We don't want to trigger the environment menu's ClickAwayListener
          // when the dialog is closed via a backdrop click.
          if (reason === 'backdropClick') {
            e.stopPropagation()
          }
          setLanguage(null)
        }}
      >
        {language && (
          <>
            <CloseButton color="inherit" onClick={() => setLanguage(null)} aria-label="close">
              <Close />
            </CloseButton>
            <LanguageContent onClick={(e) => e.stopPropagation()} component={DialogContent}>
              <div className="language-header">
                <Box display="flex" alignItems="center">
                  <LanguageIcon language={language.name} height={32} />
                  <h2 style={{ paddingLeft: 14 }}>
                    {language.category === 'jupyter'
                      ? `Jupyter - ${language.display}`
                      : language.display}
                  </h2>
                </Box>
              </div>
              {language.category === 'jupyter' ? (
                <small />
              ) : (
                <small>
                  Running {language.version} - Intellisense is{' '}
                  {language.language_client_id ? 'enabled' : 'disabled'}
                </small>
              )}
              <LanguageInfo
                language={language.name}
                testCasesEnabled={!!question?.testCasesEnabled}
              />
              <p>
                Are there any libraries or settings we missed? Feel free to{' '}
                <a href="mailto:support@coderpad.io?subject=CoderPad%20Language%20Suggestion">
                  email us
                </a>{' '}
                with suggestions!
              </p>
            </LanguageContent>
            {environment != null && environment.language !== language.name && (
              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    createOrSelectEnvironment(language)
                    setLanguage(null)
                  }}
                >
                  Switch to {language.display}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </LanguageInfoContext.Provider>
  )
}

/**
 * Convenience hook
 */
export function useLanguageInfo() {
  const context = useContext(LanguageInfoContext)

  if (!context) {
    throw new Error('`useLanguageInfo` hook must be a descendant of a `LanguageInfoProvider`')
  }

  return context
}
