import { ApolloCache } from '@apollo/client'

/**
 * Helper to make a function that will evict an entity from the Apollo cache. Usage is as follows...
 *
 * ```
 * // Import the helper.
 * import { makeEvictor } from 'graphql/utils/cacheUtils'
 *
 * // Define the type for the operation that will be performed.
 * interface IMutationPayloadData {
 *   mutationPayload: MutationPayload
 * }
 *
 * // Create the evictor with the type that will be evicted.
 * const evictor = makeEvictor<IDeleteMutationData>()
 *
 * // Use in the `update` option to the mutation.
 * const [deleteMe] = useMutation(MUTATION_QUERY, {
 *   update(cache, { data }) {
 *     // Find the entites in the mutation payload.
 *     const entityToDelete = data?.path?.to?.entity
 *
 *     evictor(entityToDelete)
 *   }
 * })
 * ```
 *
 * @param cache Apollo cache to evict the entity from.
 * @param entity Entity to evict from the Apollo cache.
 */
export function makeEvictor<TSer>() {
  return function evictor(cache: ApolloCache<TSer>, entity: any) {
    const cacheKey = cache.identify(entity)
    if (cacheKey) {
      cache.evict({ id: cacheKey })
    }
  }
}
