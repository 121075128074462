import { useTheme } from '@mui/material'
import clsx from 'classnames'
import React from 'react'

import { ReactComponent as AngularIcon } from '../../../../../assets/images/language_icons/angular.svg'
import { ReactComponent as BashIcon } from '../../../../../assets/images/language_icons/bash.svg'
import { ReactComponent as BashLightModeIcon } from '../../../../../assets/images/language_icons/bash_light_mode.svg'
import { ReactComponent as CIcon } from '../../../../../assets/images/language_icons/c.svg'
import { ReactComponent as ClojureIcon } from '../../../../../assets/images/language_icons/clojure.svg'
import { ReactComponent as CoffeescriptIcon } from '../../../../../assets/images/language_icons/coffeescript.svg'
import { ReactComponent as CoffeescriptLightModeIcon } from '../../../../../assets/images/language_icons/coffeescript_light_mode.svg'
import { ReactComponent as CppIcon } from '../../../../../assets/images/language_icons/cpp.svg'
import { ReactComponent as CSharpIcon } from '../../../../../assets/images/language_icons/csharp.svg'
import { ReactComponent as CssIcon } from '../../../../../assets/images/language_icons/css.svg'
import { ReactComponent as DartIcon } from '../../../../../assets/images/language_icons/dart.svg'
import { ReactComponent as DjangoIcon } from '../../../../../assets/images/language_icons/django.svg'
import { ReactComponent as ErlangIcon } from '../../../../../assets/images/language_icons/erlang.svg'
import { ReactComponent as ErlangLightModeIcon } from '../../../../../assets/images/language_icons/erlang_light_mode.svg'
import { ReactComponent as FSharpIcon } from '../../../../../assets/images/language_icons/fsharp.svg'
import { ReactComponent as GinIcon } from '../../../../../assets/images/language_icons/gin.svg'
import { ReactComponent as GoIcon } from '../../../../../assets/images/language_icons/go.svg'
import { ReactComponent as GsheetsIcon } from '../../../../../assets/images/language_icons/gsheets.svg'
import { ReactComponent as HaskellIcon } from '../../../../../assets/images/language_icons/haskell.svg'
import { ReactComponent as HaskellLightModeIcon } from '../../../../../assets/images/language_icons/haskell_light_mode.svg'
import { ReactComponent as HtmlIcon } from '../../../../../assets/images/language_icons/html.svg'
import { ReactComponent as HuggingFaceIcon } from '../../../../../assets/images/language_icons/huggingface.svg'
import { ReactComponent as JavaIcon } from '../../../../../assets/images/language_icons/java.svg'
import { ReactComponent as JavaLightModeIcon } from '../../../../../assets/images/language_icons/java_light_mode.svg'
import { ReactComponent as JavascriptIcon } from '../../../../../assets/images/language_icons/javascript.svg'
import { ReactComponent as JuliaIcon } from '../../../../../assets/images/language_icons/julia_dots.svg'
import { ReactComponent as JupyterIcon } from '../../../../../assets/images/language_icons/jupyter.svg'
import { ReactComponent as LuaIcon } from '../../../../../assets/images/language_icons/lua_logo.svg'
import { ReactComponent as MarkdownIcon } from '../../../../../assets/images/language_icons/markdown.svg'
import { ReactComponent as MarkdownLightModeIcon } from '../../../../../assets/images/language_icons/markdown_light_mode.svg'
import { ReactComponent as MySqlIcon } from '../../../../../assets/images/language_icons/mysql.svg'
import { ReactComponent as NextjsIcon } from '../../../../../assets/images/language_icons/nextjs.svg'
import { ReactComponent as NodeIcon } from '../../../../../assets/images/language_icons/node.svg'
import { ReactComponent as ObjectiveCIcon } from '../../../../../assets/images/language_icons/objc.svg'
import { ReactComponent as UnknownIcon } from '../../../../../assets/images/language_icons/other.svg'
import { ReactComponent as PerlIcon } from '../../../../../assets/images/language_icons/perl.svg'
import { ReactComponent as PhpIcon } from '../../../../../assets/images/language_icons/php.svg'
import { ReactComponent as PhpLightModeIcon } from '../../../../../assets/images/language_icons/php_light_mode.svg'
import { ReactComponent as PlaintextIcon } from '../../../../../assets/images/language_icons/plaintext.svg'
import { ReactComponent as PlaintextLightModeIcon } from '../../../../../assets/images/language_icons/plaintext_light_mode.svg'
import { ReactComponent as PostgresqlIcon } from '../../../../../assets/images/language_icons/postgresql.svg'
import { ReactComponent as PysparkIcon } from '../../../../../assets/images/language_icons/pyspark.svg'
import { ReactComponent as PythonIcon } from '../../../../../assets/images/language_icons/python.svg'
import { ReactComponent as PytorchIcon } from '../../../../../assets/images/language_icons/pytorch.svg'
import { ReactComponent as RailsIcon } from '../../../../../assets/images/language_icons/rails.svg'
import { ReactComponent as ReactIcon } from '../../../../../assets/images/language_icons/react.svg'
import { ReactComponent as RubyIcon } from '../../../../../assets/images/language_icons/ruby.svg'
import { ReactComponent as RustIcon } from '../../../../../assets/images/language_icons/rust.svg'
import { ReactComponent as RustLightModeIcon } from '../../../../../assets/images/language_icons/rust_light_mode.svg'
import { ReactComponent as ScalaIcon } from '../../../../../assets/images/language_icons/scala.svg'
import { ReactComponent as ScipyIcon } from '../../../../../assets/images/language_icons/scipy.svg'
import { ReactComponent as SolidityIcon } from '../../../../../assets/images/language_icons/solidity.svg'
import { ReactComponent as SolidityLightModeIcon } from '../../../../../assets/images/language_icons/solidity_light_mode.svg'
import { ReactComponent as SpringIcon } from '../../../../../assets/images/language_icons/spring.svg'
import { ReactComponent as SvelteIcon } from '../../../../../assets/images/language_icons/svelte.svg'
import { ReactComponent as SwiftIcon } from '../../../../../assets/images/language_icons/swift.svg'
import { ReactComponent as TclIcon } from '../../../../../assets/images/language_icons/tcl.svg'
import { ReactComponent as TensorflowIcon } from '../../../../../assets/images/language_icons/tensorflow.svg'
import { ReactComponent as TypescriptIcon } from '../../../../../assets/images/language_icons/typescript.svg'
import { ReactComponent as UniversalIcon } from '../../../../../assets/images/language_icons/universal.svg'
import { ReactComponent as VerilogIcon } from '../../../../../assets/images/language_icons/verilog.svg'
import { ReactComponent as VueIcon } from '../../../../../assets/images/language_icons/vuejs.svg'
import { Elixir, ElixirLight, Kotlin, OCaml, R, VB } from './icons'

const IconByLanguage = (darkColorScheme: boolean) => {
  return {
    angular: AngularIcon,
    bash: darkColorScheme ? BashIcon : BashLightModeIcon,
    c: CIcon,
    clojure: ClojureIcon,
    coffeescript: darkColorScheme ? CoffeescriptIcon : CoffeescriptLightModeIcon,
    cpp: CppIcon,
    csharp: CSharpIcon,
    css: CssIcon,
    dart: DartIcon,
    django: DjangoIcon,
    elixir: darkColorScheme ? Elixir : ElixirLight,
    erlang: darkColorScheme ? ErlangIcon : ErlangLightModeIcon,
    fsharp: FSharpIcon,
    go: GoIcon,
    gin: GinIcon,
    gsheets: GsheetsIcon,
    haskell: darkColorScheme ? HaskellIcon : HaskellLightModeIcon,
    html: HtmlIcon,
    java: darkColorScheme ? JavaIcon : JavaLightModeIcon,
    javascript: JavascriptIcon,
    jsx: JavascriptIcon,
    julia: JuliaIcon,
    'jupyter-minimal': JupyterIcon,
    'jupyter-pyspark': PysparkIcon,
    'jupyter-r': R,
    'jupyter-scipy': ScipyIcon,
    'jupyter-tensorflow': TensorflowIcon,
    'jupyter-pytorch': PytorchIcon,
    'jupyter-transformers': HuggingFaceIcon,
    multifile_java: JavaIcon,
    multifile_python: PythonIcon,
    multifile_typescript: TypescriptIcon,
    multifile_ruby: RubyIcon,
    multifile_go: GoIcon,
    multifile_csharp: CSharpIcon,
    multifile_c: CIcon,
    multifile_cpp: CppIcon,
    multifile_scala: ScalaIcon,
    nextjs: NextjsIcon,
    kotlin: Kotlin,
    lua: LuaIcon,
    markdown: darkColorScheme ? MarkdownIcon : MarkdownLightModeIcon,
    mysql: MySqlIcon,
    nodejs: NodeIcon,
    objc: ObjectiveCIcon,
    ocaml: OCaml,
    perl: PerlIcon,
    php: darkColorScheme ? PhpIcon : PhpLightModeIcon,
    plaintext: darkColorScheme ? PlaintextIcon : PlaintextLightModeIcon,
    postgresql: PostgresqlIcon,
    python: PythonIcon,
    python3: PythonIcon,
    r: R,
    rails: RailsIcon,
    react: ReactIcon,
    'react-native-web': ReactIcon,
    ruby: RubyIcon,
    rust: darkColorScheme ? RustIcon : RustLightModeIcon,
    scala: ScalaIcon,
    solidity: darkColorScheme ? SolidityIcon : SolidityLightModeIcon,
    spring: SpringIcon,
    svelte: SvelteIcon,
    swift: SwiftIcon,
    tcl: TclIcon,
    typescript: TypescriptIcon,
    unassigned: UniversalIcon,
    universal: UniversalIcon,
    vb: VB,
    verilog: VerilogIcon,
    vue: VueIcon,
  }
}

export const LanguageIconKey = {
  angular: 'angular',
  bash: 'bash',
  c: 'c',
  clojure: 'clojure',
  coffeescript: 'coffeescript',
  cpp: 'cpp',
  csharp: 'csharp',
  css: 'css',
  dart: 'dart',
  django: 'django',
  elixir: 'elixir',
  erlang: 'erlang',
  fsharp: 'fsharp',
  go: 'go',
  haskell: 'haskell',
  html: 'html',
  java: 'java',
  javascript: 'javascript',
  jsx: 'jsx',
  julia: 'julia',
  kotlin: 'kotlin',
  lua: 'lua',
  markdown: 'markdown',
  mysql: 'mysql',
  nextjs: 'nextjs',
  nodejs: 'nodejs',
  objc: 'objc',
  ocaml: 'ocaml',
  perl: 'perl',
  php: 'php',
  plaintext: 'plaintext',
  postgresql: 'postgresql',
  python: 'python',
  python3: 'python3',
  r: 'r',
  rails: 'rails',
  react: 'react',
  'react-native-web': 'react-native-web',
  ruby: 'ruby',
  rust: 'rust',
  scala: 'scala',
  solidity: 'solidity',
  spring: 'spring',
  svelte: 'svelte',
  swift: 'swift',
  tcl: 'tcl',
  typescript: 'typescript',
  unassigned: 'unassigned',
  universal: 'universal',
  vb: 'vb',
  verilog: 'verilog',
  vue: 'vue',
} as const

const LanguageIcon: React.FC<{
  language: string
  className?: string
  id?: string
  width?: number | string
  height?: number | string
}> = ({ language, className, id, width, height }) => {
  const theme = useTheme()
  const darkColorScheme = theme.palette.mode === 'dark'
  const Icon = IconByLanguage(darkColorScheme)[language] ?? UnknownIcon
  return <Icon className={clsx('language-icon', className)} id={id} width={width} height={height} />
}

export default LanguageIcon
