import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const R: React.FC<SvgIconProps> = (props) => {
  const svgId = props.id ?? 'r'

  return (
    <SvgIcon {...props} viewBox="0 0 18 14">
      <g clipPath={`url(#${svgId}_clip0_1574_1805)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.98621 12.048C4.03562 12.048 0.0223389 9.36763 0.0223389 6.0612C0.0223389 2.75476 4.03562 0.0743713 8.98621 0.0743713C13.9368 0.0743713 17.9501 2.75476 17.9501 6.0612C17.9501 9.36763 13.9368 12.048 8.98621 12.048ZM10.3583 2.41502C6.5954 2.41502 3.54497 4.24745 3.54497 6.50786C3.54497 8.76827 6.5954 10.6007 10.3583 10.6007C14.1212 10.6007 16.8981 9.34792 16.8981 6.50786C16.8981 3.66869 14.1212 2.41502 10.3583 2.41502Z"
          fill={`url(#${svgId}_paint0_linear_1574_1805)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6743 9.34714C13.6743 9.34714 14.2168 9.51041 14.532 9.66946C14.6414 9.72465 14.8306 9.83478 14.9671 9.97937C15.1008 10.121 15.166 10.2645 15.166 10.2645L17.3041 13.8595L13.8483 13.8611L12.2323 10.8347C12.2323 10.8347 11.9014 10.2677 11.6977 10.1033C11.5279 9.96621 11.4555 9.91739 11.2875 9.91739C11.1706 9.91739 10.4664 9.91739 10.4664 9.91739L10.4671 13.8589L7.40906 13.8602V3.79189H13.5499C13.5499 3.79189 16.3469 3.84219 16.3469 6.4959C16.3469 9.14961 13.6743 9.34714 13.6743 9.34714ZM12.3441 5.97584L10.4929 5.97465L10.4919 7.68663L12.3441 7.68604C12.3441 7.68604 13.2019 7.68338 13.2019 6.81517C13.2019 5.9295 12.3441 5.97584 12.3441 5.97584Z"
          fill={`url(#${svgId}_paint1_linear_1574_1805)`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${svgId}_paint0_linear_1574_1805`}
          x1="0.0223389"
          y1="0.0743713"
          x2="11.0827"
          y2="16.6346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CBCED0" />
          <stop offset="1" stopColor="#84838B" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint1_linear_1574_1805`}
          x1="7.40906"
          y1="3.79189"
          x2="17.4767"
          y2="13.6854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#276DC3" />
          <stop offset="1" stopColor="#165CAA" />
        </linearGradient>
        <clipPath id={`${svgId}_clip0_1574_1805`}>
          <rect width="18" height="13.9091" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
