import { gql, useQuery } from '@apollo/client'
import { Language, ProjectTemplate } from 'graphql/types'
import { useMemo } from 'react'

import * as queryStates from '../../../../../graphql/queryStates'

export const SUPPORTED_LANGS_QUERY = gql`
  query SupportedLanguages($padSlug: String!) {
    languages {
      id
      name
      testCaseGrading
      category
      languageClientId
      version
    }
    projectTemplates(padSlug: $padSlug) {
      id
      name
      slug
      category
    }
  }
`

export type LanguageVariant = Pick<
  Language,
  'id' | 'name' | 'category' | 'languageClientId' | 'version' | 'testCaseGrading'
>
export type ProjectTemplateVariant = Pick<ProjectTemplate, 'id' | 'slug' | 'name' | 'category'>
export type SupportedLanguage = LanguageVariant | ProjectTemplateVariant
export function isProjectTemplateVariation(
  variation: LanguageVariant | ProjectTemplateVariant
): variation is ProjectTemplateVariant {
  return (variation as ProjectTemplateVariant).slug != null
}

interface LanguageEnvironmentsData {
  languages: LanguageVariant[]
  projectTemplates: ProjectTemplateVariant[]
}

export function useSupportedLanguages(padSlug?: string) {
  const { data, loading, error } = useQuery<LanguageEnvironmentsData>(SUPPORTED_LANGS_QUERY, {
    variables: { padSlug },
  })

  const status = useMemo(
    () =>
      loading
        ? queryStates.loading()
        : error
        ? queryStates.error('error fetching supported languages', error)
        : queryStates.success(),
    [loading, error]
  )

  const supportedLanguages = useMemo<SupportedLanguage[]>(() => {
    const allLangs = [...(data?.languages ?? []), ...(data?.projectTemplates ?? [])]

    const classicHtmlIdx = allLangs.findIndex(
      (l) => !isProjectTemplateVariation(l) && l.id === 'html'
    )
    const templateHtmlIdx = allLangs.findIndex(
      (l) => isProjectTemplateVariation(l) && l.slug === 'html'
    )

    if (classicHtmlIdx >= 0 && templateHtmlIdx >= 0) {
      allLangs.splice(classicHtmlIdx, 1)
    }

    return allLangs.sort((a, b) => {
      // sort by name property ascending
      return (a.name < b.name ? -1 : 1) as -1 | 1
    })
  }, [data])

  return {
    status,
    supportedLanguages,
  }
}
