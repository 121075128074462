import Monaco from '@codingame/monaco-editor-react'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import type { CodeBlockRendererProps, CodeSpanRendererProps } from 'react-marked-renderer'
import { DEFAULT_MARKDOWN_RENDERERS, Markdown } from 'react-marked-renderer/dist/index.mjs'

const renderers = {
  ...DEFAULT_MARKDOWN_RENDERERS,
  codeblock: function CodeBlock({ lang, text }: CodeBlockRendererProps) {
    return (
      <Box py={2}>
        <Monaco
          value={text}
          programmingLanguage={lang}
          height="auto"
          minHeight={0}
          options={{
            folding: false,
            glyphMargin: false,
            hideCursorInOverviewRuler: true,
            lineNumbers: 'off',
            minimap: {
              enabled: false,
            },
            overviewRulerLanes: 0,
            padding: {
              bottom: 0,
              top: 20, // matches the STATUS_BAR_HEIGHT hardcoded here https://github.com/CodinGame/monaco-editor-react/blob/main/src/MonacoEditor.tsx#L8
            },
            renderLineHighlight: 'none',
            scrollbar: {
              handleMouseWheel: false,
              horizontal: 'hidden',
              vertical: 'hidden',
            },
            scrollBeyondLastLine: false,
            readOnly: true,
          }}
        />
      </Box>
    )
  },

  codespan: function CodeSpan({ text }: CodeSpanRendererProps) {
    return <code style={{ padding: '.1rem .3rem .2rem', borderRadius: '.2rem' }}>{text}</code>
  },
}

export const LanguageInfo = ({
  language,
  testCasesEnabled,
}: {
  language: string
  testCasesEnabled: boolean
}) => {
  const [markdown, setMarkdown] = useState<string | undefined>()

  useEffect(() => {
    if (language === null) {
      return
    }

    import(`./${testCasesEnabled ? 'TestCases/' : ''}${language}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setMarkdown(res))
          .catch((err) => console.log(err))
      })
      .catch((err) => console.log(err))
  }, [language, testCasesEnabled])

  return <Markdown markdown={markdown ?? ''} renderers={renderers} />
}
